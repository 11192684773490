<template src="../templates/scenario-actions.html"></template>
<script>

import FormButton from '@f/Button';

export default {
    name: 'ScenarioActions',
    components: {
        FormButton,
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        name: {
            type: String,
            required: false,
            default: '',
        },
        type: {
            type: String,
            required: false,
            default: 'SCENARIO',
        },
        add: {
            type: Boolean,
            default: true,
        },
        edit: {
            type: Boolean,
            default: true,
        },
        remove: {
            type: Boolean,
            default: true,
        },
        restore: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['action'],
    methods: {
        call(action, id, name) {
            this.$emit('action', {
                action, id, name
            });
        }
    }
};
</script>
<style src="../styles/scenario-actions.scss" lang="scss" />
