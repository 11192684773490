<template src="./templates/index.html"></template>

<script>
import PageTitle from '@c/PageTitle';
import Breadcrumbs from '@c/Breadcrumbs';
import PageHeader from '@c/Header';
import Modal from '@c/Modal';
import Confirm from '@c/Confirm';
import FormButton from '@f/Button';

import {
    getScenarios,
    saveScenario,
    removeScenario,
    restoreScenario,
    saveScenarioGroup,
    getScenarioGroups,
    removeScenarioGroup,
    restoreScenarioGroup
} from '@api/scenarios';
import ScenariosTree from './components/scenarios-tree';
import ScenarioFilter from './components/scenarios-filter';
import EditEntity from './components/edit-entity';
import ScenarioActions from './components/scenario-actions';

export default {
    name: 'ScenariosList',
    components: {
        PageTitle,
        Breadcrumbs,
        PageHeader,
        Modal,
        ScenarioFilter,
        FormButton,
        ScenariosTree,
        ScenarioActions,
        Confirm,
        EditEntity,
    },
    props: {
        size: {
            type: Number,
            required: false,
            default: 15,
        },
    },
    data() {
        return {
            editScenarioData: null,

            showEditEntity: false,
            editEntityData: {
                id: null,
                scenario_group_id: null,
                name: '',
            },
            editEntityType: 'SCENARIO', //'GROUP',

            showConfirmModal: false,
            confirmBts: {
                title: '',
                buttons: []
            },
            filter: '',
            showRemoved: false,
        };
    },
    computed: {},
    watch: {},
    methods: {
        actionHandler(args) {
            switch (args.action) {
                case 'add-scenario':
                    this.addScenario(args.id);
                    break;
                case 'edit-scenario':
                    this.editScenario(args.id);
                    break;
                case 'delete-scenario':
                    this.deleteScenario(args.id);
                    break;
                case 'restore-scenario':
                    this.restoreScenario(args.id);
                    break;
                case 'add-group':
                    this.addScenarioGroup(args.id);
                    break;
                case 'edit-group':
                    this.editScenarioGroup(args.id);
                    break;
                case 'delete-group':
                    this.deleteScenarioGroup(args.id);
                    break;
                case 'restore-group':
                    this.restoreScenarioGroup(args.id);
                    break;
                default:
                    console.log('Error 6140: Неизвестное действие');
            }
        },
        editEntity(type, data) {
            this.editEntityData = {
                ...data,
            };
            this.editEntityType = type;
            this.showEditEntity = true;
        },
        saveEntity(type, data) {
            const successHandler = () => {
                const ch = this.$refs.varTree;
                ch.onUpdateTree();
                this.closeEditEntityModal();
            };

            switch (type) {
                case 'SCENARIO':
                    saveScenario({...data}, successHandler);
                    break;
                case 'GROUP':
                    saveScenarioGroup({...data}, successHandler);
                    break;
                default:
                    console.log('Error 7a345s: Неизвестная сущность');
                    break;
            }
        },
        closeEditEntityModal() {
            this.showEditEntity = false;
        },
        deleteScenarioGroup(groupId) {
            getScenarioGroups(
                {
                    id: groupId,
                    limit: 1,
                    removed: 0,
                },
                r => {
                    this.confirmBts = {
                        title: `Вы уверены, что хотите удалить группу сценариев "${r[groupId].name}"?`,
                        buttons: [
                            {
                                label: 'Да',
                                class: 'btn-red',
                                action: () => {
                                    removeScenarioGroup(
                                        {id: groupId},
                                        () => {
                                            const ch = this.$refs.varTree;
                                            ch.onUpdateTree();
                                            this.showConfirmModal = false;
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Отмена',
                                action: () => {
                                    this.showConfirmModal = false;
                                },
                            },
                        ]
                    };

                    this.showConfirmModal = true;
                }
            );
        },
        restoreScenarioGroup(groupId) {
            getScenarioGroups(
                {
                    id: groupId,
                    limit: 1,
                    removed: 1,
                },
                r => {
                    this.confirmBts = {
                        title: `Вы уверены, что хотите восстановить группу сценариев "${r[groupId].name}"?`,
                        buttons: [
                            {
                                label: 'Да',
                                class: 'btn-red',
                                action: () => {
                                    restoreScenarioGroup(
                                        groupId,
                                        () => {
                                            const ch = this.$refs.varTree;
                                            ch.onUpdateTree();
                                            this.showConfirmModal = false;
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Отмена',
                                action: () => {
                                    this.showConfirmModal = false;
                                },
                            },
                        ]
                    };

                    this.showConfirmModal = true;
                }
            );
        },
        editScenarioGroup(groupId) {
            getScenarioGroups(
                {
                    id: groupId,
                    limit: 1,
                    removed: 0,
                },
                r => {
                    this.editEntity('GROUP', {...r[groupId]});
                }
            );
        },
        addScenarioGroup(parentId) {
            this.editEntity('GROUP', {scenario_group_id: parentId});
        },
        editScenario(id) {
            this.$router.push({name: 'Scenario', params: {id}});
        },
        addScenario(parentId) {
            this.editEntity('SCENARIO', {scenario_group_id: parentId});
        },
        deleteScenario(scenarioId) {
            getScenarios(
                {
                    id: scenarioId,
                    limit: 1,
                    removed: 0,
                },
                r => {
                    this.confirmBts = {
                        title: `Вы уверены, что хотите удалить сценарий "${r[scenarioId].name}"?`,
                        buttons: [
                            {
                                label: 'Да',
                                class: 'btn-red',
                                action: () => {
                                    removeScenario(
                                        {id: scenarioId},
                                        () => {
                                            const ch = this.$refs.varTree;
                                            ch.onUpdateTree();
                                            this.showConfirmModal = false;
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Отмена',
                                action: () => {
                                    this.showConfirmModal = false;
                                },
                            },
                        ]
                    };

                    this.showConfirmModal = true;
                }
            );

        },
        restoreScenario(scenarioId) {
            getScenarios(
                {
                    id: scenarioId,
                    limit: 1,
                    removed: 1,
                },
                r => {
                    this.confirmBts = {
                        title: `Вы уверены, что хотите восстановить сценарий "${r[scenarioId].name}"?`,
                        buttons: [
                            {
                                label: 'Да',
                                class: 'btn-red',
                                action: () => {
                                    restoreScenario(
                                        scenarioId,
                                        () => {
                                            const ch = this.$refs.varTree;
                                            ch.onUpdateTree();
                                            this.showConfirmModal = false;
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Отмена',
                                action: () => {
                                    this.showConfirmModal = false;
                                },
                            },
                        ]
                    };

                    this.showConfirmModal = true;
                }
            );
        },
    },
};
</script>

<style src="./styles/scenarios-list.scss" lang="scss"/>
