<div class="scenario-actions">
    <form-button
        v-if="add"
        type="a"
        class="btn btn-sm btn-green m-auto text-center"
        @click="call('add-scenario', id)"
    >
        <template v-slot:center>
            <span class="icon plus green m-auto"></span>
        </template>
    </form-button>

    <form-button
        v-if="edit"
        type="a"
        class="btn btn-sm btn-blue text-center m-1"
        @click="call(type === 'SCENARIO' ? 'edit-scenario' : 'edit-group', id, name)"
    >
        <template v-slot:center>
            <span class="icon edit green m-auto"></span>
        </template>
    </form-button>

    <form-button
        v-if="remove"
        type="a"
        class="btn btn-sm btn-red m-auto text-center"
        @click="call(type === 'SCENARIO' ? 'delete-scenario' : 'delete-group', id)"
    >
        <template v-slot:center>
            <span class="icon delete red m-auto"></span>
        </template>
    </form-button>

    <form-button
        v-if="restore"
        type="a"
        class="btn btn-sm btn-red m-auto text-center"
        @click="call(type === 'SCENARIO' ? 'restore-scenario' : 'restore-group', id)"
    >
        <template v-slot:center>
            <span class="icon delete red m-auto"></span>
        </template>
    </form-button>
</div>
