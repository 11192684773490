<template src="../templates/scenarios-tree.html"></template>
<script>

import Tree from '@c/Tree';

import {
    getScenariosTree
} from '@api/scenarios';

export default {
    name: 'ScenariosTree',
    components: {
        Tree,
    },
    props: {
        selectedCategoryId: {
            type: [Number, String],
            default: null,
        },
        selectedCategory: {
            type: Object,
            default: null,
        },
        filter: {
            type: String,
            default: '',
        },
        showRemoved: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:selectedCategory', 'update:selectedCategoryId'],
    data() {
        return {
            scenarios: [],
            scenariosTree: [],
            changeCategory: null,
        };
    },
    watch: {
        changeCategory() {
            this.$emit('update:selectedCategoryId', this.changeCategory);

            const category = Object.values(this.scenarios).filter(
                item => parseInt(item.id, 10) === parseInt(this.changeCategory, 10)
            )[0];
            this.$emit('update:selectedCategory', category);
        },
        showRemoved() {
            this.onUpdateTree();
        }
    },
    created() {
        this.onUpdateTree();
    },
    methods: {
        initComponent(callback = () => {
        }) {
            this.scenarios = [];
            const filter = {
                scenarioFilter: {},
                scenarioGroupFilter: {},
            };
            if (!this.showRemoved) {
                filter.scenarioFilter.removed = 0;
                filter.scenarioGroupFilter.removed = 0;
            }
            getScenariosTree(
                filter,
                r => {
                    this.scenarios = r;
                    callback();
                }
            );
        },
        buildTree(parentId = null) {

            const values = Object.values(this.scenarios)
                .filter(item => item.parent_id === parentId);
                //.sort(this.compare);

            values.forEach((item, i) => {
                values[i].children = [...this.buildTree(item.id), ...Object.values(values[i].scenarios)];
            });

            return values;
        },
        compare(a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        },
        onUpdateTree() {
            this.initComponent(() => {
                this.scenariosTree = this.buildTree();

                if (this.selectedCategoryId) {
                    this.changeCategory = this.selectedCategoryId;
                } else if (this.selectedCategory && this.selectedCategory.id) {
                    this.changeCategory = this.selectedCategory.id;
                }
            });
        }
    }
};
</script>
