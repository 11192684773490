<template src="../templates/edit-entity.html"></template>
<script>

import Modal from '@c/Modal';
import FormInput from '@f/Input';
import FormButton from '@f/Button';
import FormTextarea from '@f/Textarea';
import '../styles.scss';

export default {
    name: 'EditEntity',
    components: {
        Modal,
        FormInput,
        FormButton,
        FormTextarea,
    },
    props: {
        type: {
            type: String,
            default() { return ''; }
        },
        data: {
            type: Object,
            default() { return {}; }
        },
        onSave: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        onCancel: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    data() {
        return {
            name: this.data.name,
            modified: false,
            errors: {
                editValueErrors: [],
            },
        };
    },
    computed: {
        validated() {
            return !!this.name
                && this.name.length >= 3
                && this.errors.editValueErrors.length === 0;
        },
    },
    methods: {
         save() {
            this.onSave(this.type, {...this.data, name: this.name});
        },
    }
};
</script>
<style src="../styles/edit-scenario.scss" lang="scss"></style>
