<div>
    <page-header>
        <div class="row c">
            <breadcrumbs :items="[{title:'Сценарии'}, {title:'Список сценариев'}]"/>
        </div>
    </page-header>

    <page-title title="Сценарии" :params="{/*subtitle:'Список сценариев'*/}"/>

    <div class="container">
        <div class="row filter-block c mt-4 mb-3 flex-direction-column">
            <div class="row-col w-100">
                <div class="col-9">
                    <ScenarioFilter
                        v-model:filter="filter"
                        v-model:show-removed="showRemoved"
                    />
                </div>
                <div class="col-3">
                    <FormButton
                            label="Создать группу сценариев"
                            :attributes="{class: ['btn', 'btn-green', 'w100']}"
                            @click="editEntity('GROUP', {})"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="row c mt-4 mb-4">
        <div class="row-col">
            <div class="col scenarios-tree-content">
                <scenarios-tree
                    ref="varTree"
                    :filter="filter"
                    :show-removed="showRemoved"
                >
                    <template v-slot:line="row">
                        <template v-if="parseInt(row.data.removed, 10) === 0">
                            <template v-if="typeof row.data.children !== 'undefined' && row.data.children.length > 0">
                                <span class="icon folder gray m-auto"></span>
                                {{row.data.name}}
                            </template>
                            <span v-else>{{row.data.name}}</span>
                        </template>
                        <template v-else>
                            <s>{{row.data.name}}</s>
                        </template>
                        <scenario-actions
                            :id="parseInt(row.data.id, 10)"
                            :name="row.data.name"
                            :type="typeof row.data.children !== 'undefined' && row.data.children.length > 0 ? 'GROUP' : 'SCENARIO'"
                            :add="parseInt(row.data.removed, 10) === 0 && typeof row.data.children !== 'undefined'"
                            :remove="parseInt(row.data.removed, 10) === 0"
                            :restore="parseInt(row.data.removed, 10) === 1"
                            @action="actionHandler"
                        />
                    </template>
                </scenarios-tree>
            </div>
        </div>
    </div>

    <teleport to="body" v-if="showConfirmModal">
        <confirm
            v-model:show-modal="showConfirmModal"
            :title="confirmBts.title"
            :buttons="confirmBts.buttons"
        />
    </teleport>

    <teleport to="body" v-if="showEditEntity">
        <modal
            @onOk="closeEditEntityModal"
            @onClose="closeEditEntityModal"
        >
            <edit-entity
                :type="editEntityType"
                :data="editEntityData"
                :on-save="saveEntity"
                :on-cancel="closeEditEntityModal"
            />
        </modal>
    </teleport>
</div>
