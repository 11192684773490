<div id="edit-scenario-popup" class="p-2">
    <div class="row-col mw-2">
        <div class="col-11 ph-3">
            <div class="form-row m-0">
                <div class="form-row-add">
                    <span class="icon folder gray lg x5 m-auto"></span>
                </div>
                <div class="col">
                    <FormTextarea
                        :attributes="{
                            placeholder: `Имя ${type === 'SCENARIO' ? 'сценария': 'группы сценариев'}`,
                            name: 'description',
                            class: 'input-head'
                        }"
                        v-model="name"
                        :flexible="false"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="row-col">
        <div class="col p-3">
            <div class="position-sticky bottom-n80 action-panel pv-1 mb-4">
                <div class="ml-2">
                    <FormButton
                        :disabled="!validated"
                        label="Сохранить"
                        :attributes="{class: ['btn-green', 'mr-1']}"
                        @click="save"
                    />
                    <FormButton label="Отмена" @click="onCancel" :attributes="{class: ['btn-gray']}"/>
                </div>
            </div>
        </div>
    </div>
</div>
